import React from "react"
import Navbar from "src/components/navbar"
import jobsPage from "content/data/static-pages/jobs.json"
import navbarStyles from "src/styles/components/navbar.module.scss"
import styles from "src/styles/pages/jobs.module.scss"
import sectionStyles from "src/styles/components/section.module.scss"
import Grid from "@material-ui/core/Grid"
import cx from "classnames"
import map from "lodash/map"
import useJobs from "src/static_queries/useJobs"
import JobOpening from "src/components/job-opening"
import SEO from "src/components/seo"
import Layout from "src/components/layout"

export default function AboutPage() {
  const { de: page } = jobsPage
  const jobs = useJobs()

  return (
    <Layout className={styles.root}>
      <Navbar className={navbarStyles.mint} />
      <SEO title={page.open_positions_text} image={page.jumbo_image} />
      <div
        className={styles.jumboImage}
        style={{
          backgroundImage: `url("${page.jumbo_image}")`,
          backgroundSize: "cover",
        }}
      />
      <section className={cx(sectionStyles.root, styles.titleSection)}>
        <h1>{page.title}</h1>
      </section>
      <section className={cx(sectionStyles.root, styles.aboutTextSection)}>
        <h4>{page.open_positions_text}</h4>
        <Grid container spacing={4} className={styles.jobsSection}>
          {map(jobs, ({ edges }) =>
            map(edges, ({ node: { frontmatter: job } }, index) => (
              <JobOpening job={job} showDepartment={index === 0} />
            ))
          )}
        </Grid>
      </section>
      <section className={cx(sectionStyles.root, styles.footerSection)}>
        <h4>{page.footer_text}</h4>
        {page.cta_link && (
          <a target="_blank" rel="noopener noreferrer" href={page.cta_link}>
            <button>{page.cta_text}</button>
          </a>
        )}
      </section>
    </Layout>
  )
}
