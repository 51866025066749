import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/job-opening.module.scss"
import Grid from "@material-ui/core/Grid"

export default function JobOpening({ job, showDepartment }) {
  const { title, link, department } = job

  return (
    <Grid className={styles.root} item xs={12}>
      <div>
        {showDepartment && <h5>{department}</h5>}
        <a target="_blank" rel="noopener noreferrer" href={link}>
          <h4>{title}</h4>
        </a>
      </div>
    </Grid>
  )
}

JobOpening.propTypes = {
  job: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    department: PropTypes.string,
  }),
  showDepartment: PropTypes.bool,
}

JobOpening.defaultProps = {
  showDepartment: false,
}
