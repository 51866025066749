import { graphql, useStaticQuery } from "gatsby"

export default function useJobs() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/jobs/" } }) {
        group(field: frontmatter___department) {
          fieldValue
          edges {
            node {
              id
              frontmatter {
                department
                title
                link
              }
            }
          }
        }
      }
    }
  `)
  return data.allMarkdownRemark.group
}
